// @flow
import React from 'react'

import IntroStrip from '../blocks/IntroStrip'
import TopRegisterStrip from '../blocks/TopRegisterStrip'
import VideoStrip from '../blocks/VideoStrip'
import StepsStrip, {type StepType} from '../blocks/StepsStrip'
import DefaultLayout from '../components/DefaultLayout'
import LeadFormStrip from '../blocks/LeadFormStrip'
import BlogLinkStrip from '../blocks/BlogLinkStrip'
import FeaturesStrip from '../blocks/FeaturesStrip'
import TestimonialsStrip from '../blocks/TestimonialsStrip'
import RegisterStrip from '../blocks/RegisterStrip'
import {isUserKnown} from '../lib/is-known'

const steps: StepType[] = [
  {
    title: 'Set a date and share the link',
    text:
      'Ask your friend (the Superperson) to block a few days in their calendar for a big surprise. Using the app, you create a calendar and share it with their friends you have in common.',
  },
  {
    title: 'Fill the calendar with events',
    text:
      'Superdays helps your friends with creative suggestions and they don’t even have to install the app. Move events, make comments – all that stuff is super easy.',
  },
  {
    title: 'Gift amazing Superdays',
    text:
      'When the date for the Superdays has come, your Superperson scans a code and gains access to days full of amazing events, planned by their friends.',
  },
]

const IndexPage = () => (
  <DefaultLayout
    title="Epic adventures, planned by friends!"
    description="Plan truly unique days for your friends and create a meaningful gift without effort"
  >
    <IntroStrip bottomWave="none" headline="Epic adventures, planned by friends!">
      Plan truly unique days for your friends
      <br />
      and create a meaningful gift without effort.
    </IntroStrip>
    {isUserKnown() ? <div /> : <TopRegisterStrip formMessage="Sounds fun? Join our beta list!" />}
    <VideoStrip />
    <StepsStrip headline="Plan with friends, the easy way" steps={steps} withBikeRide={true} />
    {isUserKnown() ? <div /> : <LeadFormStrip />}
    <FeaturesStrip />
    <BlogLinkStrip headline="The story behind Superdays" />
    <TestimonialsStrip />
    {isUserKnown() ? <div /> : <RegisterStrip />}
  </DefaultLayout>
)

export default IndexPage
