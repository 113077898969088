import * as React from 'react'

type WEProps = {
  videoId: string,
}
export class WistiaEmbed extends React.Component<WEProps> {
  handle: any = null
  render() {
    const {videoId} = this.props
    return (
      <div
        className="wistia_responsive_padding"
        style={{
          padding: '56.25% 0 0 0',
          position: 'relative',
          width: '100%',
          maxWidth: 680,
          boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.06)',
        }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{height: '100%', left: '0', position: 'absolute', top: '0', width: '100%'}}
        >
          <div
            className={`wistia_embed wistia_async_${videoId} videoFoam=true`}
            style={{height: '100%', width: '100%'}}
          >
            &nbsp;
          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    const {videoId} = this.props
    if (!document.getElementById('wistia_script')) {
      var wistiaScript = document.createElement('script')
      wistiaScript.id = 'wistia_script'
      wistiaScript.type = 'text/javascript'
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js'
      wistiaScript.async = true
      document && document.body && document.body.appendChild(wistiaScript)
    }
    window._wq = window._wq || []
    window._wq.push({
      id: videoId,
      onEmbedded: video => {
        this.handle = video
      },
    })
  }
  componentWillUnmount() {
    this.handle && this.handle.remove()
  }
}
