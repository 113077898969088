// @flow
import React from 'react'
import {css} from 'glamor'
import Media from 'react-media'
import Slider from '../components/SSRSlider'

import {StripWithContent, Col, Row, CardWithButton} from '../ui/layout'
import {breakPoints} from '../ui/breakpoints'
import typo, {ExternalOrInternalLink, fontStyles} from '../ui/typo'
import RegisterForm from '../components/RegisterForm'
import {ScrollTarget, SetScrollTarget} from '../components/ScrollManager'

import BirthdayImage from '../images/icon/icon__lp__birthday.svg'
import WeddingImage from '../images/icon/icon__lp--wedding.svg'
import OtherImage from '../images/icon/icon__lp--beer.svg'

const cardClass = css({
  cursor: 'pointer',
  [breakPoints.notSmall]: {flex: '10rem 1 1'},
})

const iconContainerStyle = css({
  [breakPoints.small]: {
    paddingTop: '0.75rem !important',
    paddingBottom: '0.75rem !important',
  },
})

const imageStyle = {
  width: 80,
  padding: '0.1rem',
  transitionProperty: 'opacity, transform',
  transformOrigin: '50% 50%',
  [`.${cardClass}:hover &`]: {
    transform: 'scale(1.2)',
    opacity: 0.9,
  },
}

const Option = ({label, image, onClick}) => (
  <CardWithButton onCardClick={onClick} buttonLabel={label} onClick={onClick} className={cardClass}>
    <Row align="center" justify="center" padding={2} innerClassName={iconContainerStyle}>
      <img src={image} css={imageStyle} />
    </Row>
  </CardWithButton>
)

const StepOne = ({pickOccasion}) => (
  <Col spacing={2} paddingHorizontal={2} paddingBottom={3}>
    <typo.Body css={{textAlign: 'center'}}>What’s the occasion for this gift?</typo.Body>
    <Row spacing={2} breakAt={breakPoints.small}>
      <Option label="Birthday" image={BirthdayImage} onClick={() => pickOccasion('birthday')} />
      <Option label="Wedding" image={WeddingImage} onClick={() => pickOccasion('wedding')} />
      <Option label="Something else" image={OtherImage} onClick={() => pickOccasion('other')} />
    </Row>
  </Col>
)

const labelByOccasion = {
  birthday: 'What a beautiful birthday gift!',
  wedding: 'What a beautiful wedding gift!',
  other: 'What a beautiful gift!',
}

const StepTwo = ({occasion}) => (
  <Col spacing={2} paddingBottom={1} align="center">
    <typo.Body css={{textAlign: 'center'}}>{labelByOccasion[occasion || 'other']}</typo.Body>
    <RegisterForm message="Awesome! Let’s get you started" occasion={occasion || 'other'} />
  </Col>
)

type Occassion = 'birthday' | 'wedding' | 'other'
type Props = {
  scrollTo: string => any,
  isSmallScreen: boolean,
}

type State = {
  occasion: Occassion | null,
}

class LeadFormWithScroll extends React.Component<Props, State> {
  slider: any = null

  state = {
    occasion: null,
  }

  handleSliderInstance = (slider: any) => {
    this.slider = slider
  }

  goNext = () => {
    const {scrollTo, isSmallScreen} = this.props
    this.slider.slickNext()
    if (isSmallScreen) scrollTo('leadForm')
  }

  render() {
    const {occasion} = this.state

    return (
      <ScrollTarget scrollKey="leadForm" offset={50}>
        {registerNode => (
          <StripWithContent maxWidth={50}>
            <Col align="center" paddingTop={2}>
              <typo.H2 css={{textAlign: 'center'}}>Make someone happy</typo.H2>
              <div css={{width: '100%'}} ref={registerNode}>
                <Slider
                  arrows={false}
                  draggable={false}
                  infinite={false}
                  adaptiveHeight={true}
                  ref={this.handleSliderInstance}
                >
                  <StepOne
                    pickOccasion={(occasion: Occassion) => {
                      this.setState({occasion})
                      this.goNext()
                    }}
                  />
                  <StepTwo occasion={occasion} />
                </Slider>
              </div>
            </Col>
            <Col align="center" spacing={0.5} paddingBottom={3} paddingTop={2}>
              <typo.BodyBold css={{textAlign: 'center'}}>
                Did you know that you can also gift Superdays to yourself? 😎
              </typo.BodyBold>
              <typo.Body css={{textAlign: 'center'}}>
                Create your own Superdays and invite friends to plan some great activities for you.{' '}
              </typo.Body>
              <ExternalOrInternalLink
                href="/as-treat"
                css={[fontStyles.smallBoldBody, fontStyles.link()]}
              >
                Read More
              </ExternalOrInternalLink>
            </Col>
          </StripWithContent>
        )}
      </ScrollTarget>
    )
  }
}

const LeadForm = () => (
  <SetScrollTarget>
    {scrollTo => (
      <Media query="(max-width: 500px)" defaultMatches={false}>
        {isSmallScreen => <LeadFormWithScroll isSmallScreen={isSmallScreen} scrollTo={scrollTo} />}
      </Media>
    )}
  </SetScrollTarget>
)

export default LeadForm
