// @flow
import * as React from 'react'

import {StripWithContent, Col} from '../ui/layout'
import typo from '../ui/typo'
import {breakPoints} from '../ui/breakpoints'
import {BottomWave} from '../ui/shapes'
import {WistiaEmbed} from '../components/WistiaEmbed'

const VideoStrip = () => (
  <StripWithContent
    bottom={<BottomWave style={{zIndex: 0}} />}
    css={{
      [breakPoints.small]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    }}
  >
    <Col align="center" spacing={1} paddingTop={2} paddingBottom={0.3}>
      <typo.H2>How it works</typo.H2>
      <div css={{maxWidth: 680, width: '100%'}}>
        <WistiaEmbed videoId="rtgl0tqy1n" />
      </div>
    </Col>
  </StripWithContent>
)

export default VideoStrip
